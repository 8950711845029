angular.module('usuariosAdmin')
       .controller('usuarioAdminController', ['$scope', '$routeParams', '$location', 'usuariosAdminService', function ($scope, $routeParams, $location, usuariosAdminService) {
           usuariosAdminService.getUsuario($routeParams.id)
                             .then(function (response) {
                                var data = response.data;
                                 $scope.usuario = data;
                             });
           usuariosAdminService.getRoles()
                            .then(function (response) {
                                var data = response.data;
                                $scope.roles = data;
                            });

           $scope.volver = function () {
               $location.path("/admin/usuarios");
           };

           $scope.editarUsuario = function () {
               usuariosAdminService.put($scope.usuario).then(function (response) {
                   var data = response.data;
                   alert(data);
                   $location.path("/admin/usuarios");
               }).catch(function (response) {
                   $scope.error = usuariosAdminService.obtenerPrimerErrorModelState(response.data);
                   alert($scope.error);
               });
           }
       }]);
