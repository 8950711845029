angular.module('alertasAdmin')
       .controller('usuariosAlertasAdminController', ['$scope', '$routeParams', '$location', 'alertasAdminService', function ($scope, $routeParams, $location, alertasAdminService) {
           $scope.obra = $routeParams.obra;

           alertasAdminService.getUsuariosObra($scope.obra)
                             .then(function (response) {
                                var data = response.data;
                                 $scope.usuarios = data;

                                 $scope.usuariosSeleccionadosTodosEmail = true;
                                 angular.forEach($scope.usuarios, function (usuario) {
                                     if (!(usuario.AsignadoEmail)) {
                                         $scope.usuariosSeleccionadosTodosEmail = false;
                                         return;
                                     }
                                 });
                                 $scope.usuariosSeleccionadosTodosMovil = true;
                                 angular.forEach($scope.usuarios, function (usuario) {
                                     if (!(usuario.AsignadoMovil)) {
                                         $scope.usuariosSeleccionadosTodosMovil = false;
                                         return;
                                     }
                                 });
                             });

           $scope.usuariosSeleccionarTodosEmail = function () {
               angular.forEach($scope.usuarios, function (usuario) {
                   usuario.AsignadoEmail = $scope.usuariosSeleccionadosTodosEmail;
               });
           };
           $scope.usuariosSeleccionarTodosEmailCheckear = function () {
               $scope.usuariosSeleccionadosTodosEmail = true;
               angular.forEach($scope.usuarios, function (usuario) {
                   if (!(usuario.AsignadoEmail)) {
                       $scope.usuariosSeleccionadosTodosEmail = false;
                       return;
                   }
               });
           };

           $scope.usuariosSeleccionarTodosMovil = function () {
               angular.forEach($scope.usuarios, function (usuario) {
                   usuario.AsignadoMovil = $scope.usuariosSeleccionadosTodosMovil;
               });
           };
           $scope.usuariosSeleccionarTodosMovilCheckear = function () {
               $scope.usuariosSeleccionadosTodosMovil = true;
               angular.forEach($scope.usuarios, function (usuario) {
                   if (!(usuario.AsignadoMovil)) {
                       $scope.usuariosSeleccionadosTodosMovil = false;
                       return;
                   }
               });
           };

           $scope.guardarUsuarios = function () {
               alertasAdminService.put($scope.usuarios).then(function (response) {
                   var data = response.data;
                   alert(data);
                   $location.path("/admin/alertas");
               }).catch(function (response) {
                   alert('error');
                   $scope.error = alertasAdminService.obtenerPrimerErrorModelState(response.data);
                   alert($scope.error);
               });
           }
       }]);
