angular.module('puestaEnObra', [])
       .controller('puestaEnObraController', ['$scope', '$rootScope', '$routeParams', '$location', 'authenticationInfo', 'puestaEnObraService', 'Utilidades', 'dataPersistService', 'onlineStatus', 'storage', '$cordovaFile', '$cordovaFileTransfer', function ($scope, $rootScope, $routeParams, $location, authenticationInfo, puestaEnObraService, Utilidades, dataPersistService, onlineStatus, storage, $cordovaFile, $cordovaFileTransfer) {
           $scope.authentication = authenticationInfo;
           $scope.idSupervision = ($routeParams.id == null) ? 0 : $routeParams.id;
           $scope.puestasObra = dataPersistService.getPuestaEnObra();
           $scope.IsOnLine = onlineStatus.isOnline();
           $scope.IsMobile = Utilidades.isMobileDevice();

           var titulo = dataPersistService.getTextoCabecera();
           if (titulo != null && titulo != "" && titulo.length > 0) {
             $rootScope.title = titulo;
           }

           if ($scope.IsOnLine) {
               if ($scope.puestasObra == null) {
                   if ($routeParams.id > 0) {
                       puestaEnObraService.getPuestaEnObra($routeParams.id)
                                         .then(function (response) {
                                             var data = response.data;
                                             $scope.puestasObra = data;
                                         });
                   }
               }
           }

           $scope.$on('LastRepeaterElement', function() {
            if (!$scope.added) {
              $('.panel .collapse').collapse("hide");
            }
            $scope.added = false;
          });

           $scope.inputID = "";
           $scope.uploadFile = function (input) {
               if (input.files && input.files[0]) {
                   var reader = new FileReader();
                   $scope.inputID = input.id;
                   reader.onload = $scope.imageIsLoaded;
                   $scope.imageName = input.files[0].name;
                   reader.readAsDataURL(input.files[0]);
               }
           };
           $scope.imageIsLoaded = function (e) {
               $scope.$apply(function () {
                   var index = parseInt($scope.inputID.substring('photo-upload'.length));
                   $('#imagenSeleccionada' + index).attr('src', e.target.result);

                   $scope.puestasObra[index].Imagen = e.target.result.replace(/data:image\/jpeg;base64,/g, '');
                   $scope.puestasObra[index].NombreImagen = $scope.imageName;
                   $scope.imageName = null;
               });
           }

           //Menú superior
           $scope.DatosSupervisionCargar = function () {
               dataPersistService.savePuestaEnObra($scope.puestasObra);
               $location.path("/supervisiones/" + $scope.idSupervision);
           }
           $scope.ClimatologiaCargar = function () {
               dataPersistService.savePuestaEnObra($scope.puestasObra);
               $location.path("/climatologias/" + $scope.idSupervision);
           }
           $scope.PreparacionSuperficieCargar = function () {
               dataPersistService.savePuestaEnObra($scope.puestasObra);
               $location.path("/preparacionessuperficie/" + $scope.idSupervision);
           }
           $scope.MaterialesCargar = function () {
               dataPersistService.savePuestaEnObra($scope.puestasObra);
               $location.path("/materiales/" + $scope.idSupervision);
           }
           $scope.TomasMuestrasCargar = function () {
               dataPersistService.savePuestaEnObra($scope.puestasObra);
               $location.path("/tomasmuestras/" + $scope.idSupervision);
           }
           //Fin menú superior

           $scope.Informe = function () {
               puestaEnObraService.informeSupervision($scope.idSupervision)
                .then(function (response) {
                    var data = response.data;
                    try {
                        var file = new Blob([data], { type: 'application/pdf' });
                    }
                    catch (e) {
                        // TypeError old chrome and FF
                        window.BlobBuilder = window.BlobBuilder ||
                                             window.WebKitBlobBuilder ||
                                             window.MozBlobBuilder ||
                                             window.MSBlobBuilder;
                        if (e.name == 'TypeError' && window.BlobBuilder) {
                            var bb = new BlobBuilder();
                            bb.append([data]);
                            var file = bb.getBlob("application/pdf");
                        }
                        else if (e.name == "InvalidStateError") {
                            // InvalidStateError (tested on FF13 WinXP)
                            var file = new Blob([data], { type: "application/pdf" });
                        }
                        else {
                            // blob constructor unsupported entirely   
                        }
                    }

                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(file);
                    }
                    else {
                        var fileURL = URL.createObjectURL(file);
                        if ($scope.IsMobile) {
                            alert('Informe generado correctamente');
                            window.open(fileURL, "_blank", "location=yes");
                        } else {
                            window.open(fileURL, "_blank", "location=yes");
                        }
                    }
                }).catch(function (response) {
                    alert('Error al generar el informe');
                });
           }

           $scope.NuevaPuestaEnObra = function () {
            $scope.added = true;
               if ($scope.puestasObra == null) {
                   $scope.puestasObra = [];
               } 
                var puestaObra = {
                    Id: 0 - $scope.puestasObra.length,
                    NumeroPuestaObra: $scope.puestasObra.length + 1,
                    Hora: null,
                    Aspecto: '',
                    TemperaturaPreviaExtension: null,
                    TemperaturaFinalCompactacion: null,
                    Imagen: null,
                    NombreImagen: null,
                };
               $scope.puestasObra.push(puestaObra);

                //$scope.puestasObra.splice(0, 0, puestaObra);
                //$scope.puestasObra.unshift(puestaObra);
           }
           $scope.CopiarPuestaEnObraAnterior = function () {
               $scope.added = true;
               var puestaObraAnterior = $scope.puestasObra[$scope.puestasObra.length - 1];
               var puestaObra = {
                   Id: 0 - $scope.puestasObra.length,
                   NumeroPuestaObra: $scope.puestasObra.length + 1,
                   Hora: puestaObraAnterior.Hora,
                   Aspecto: puestaObraAnterior.Aspecto,
                   TemperaturaPreviaExtension: puestaObraAnterior.TemperaturaPreviaExtension,
                   TemperaturaFinalCompactacion: puestaObraAnterior.TemperaturaFinalCompactacion,
                   Imagen: null,
                   NombreImagen: null,
               };
               $scope.puestasObra.push(puestaObra);
           }
           $scope.EliminarPuestaEnObra = function (puestasObraIndex) {
               $scope.puestasObra.splice($scope.puestasObra.indexOf(puestasObraIndex), 1);
           }

           $scope.Guardar = function () {
               for (var i = 0; i < $scope.puestasObra.length; i++) {
                   $scope.puestasObra[i].SupervisionId = $scope.idSupervision;
               }
               dataPersistService.savePuestaEnObra($scope.puestasObra);
               var supervisionCompleta = dataPersistService.getSupervisionCompleta();
               if ($scope.idSupervision < 1) {
                   if ($scope.IsOnLine) {
                       puestaEnObraService.post(supervisionCompleta).then(function (response) {
                           var data = response.data;
                           $scope.idSupervision = data;
                           alert("Supervisión creada correctamente.");
                           dataPersistService.savePuestaEnObra(null);
                           puestaEnObraService.getPuestaEnObra($scope.idSupervision)
                                             .then(function (response) {
                                                 var data = response.data;
                                                 $scope.puestasObra = data;
                                             });
                       }).catch(function (response) {
                           $scope.error = puestaEnObraService.obtenerPrimerErrorModelState(response.data);
                           alert($scope.error);
                       });
                   } else {
                       supervisionCompleta.DatosSupervision.Id = $scope.idSupervision;
                       try {
                           storage.set("supervision" + $scope.idSupervision, supervisionCompleta);
                           alert("Supervisión creada correctamente.");
                       } catch (error) {
                           alert("Error al crear la supervisión.");
                       }
                   }
               } else {
                   if ($scope.IsOnLine) {
                       puestaEnObraService.put(supervisionCompleta).then(function (response) {
                           var data = response.data;
                           alert(data);
                           dataPersistService.savePuestaEnObra(null);
                           puestaEnObraService.getCodigoSupervision($scope.idSupervision)
                                    .then(function (response) {
                                        var data = response.data;
                                        dataPersistService.setTextoCabecera("<span class='navbar-tit'>" + data[0] + "</span><br><span class='navbar-subtit'>" + data[1] + "</span>");
                                        $rootScope.title = dataPersistService.getTextoCabecera();
                                    });
                       }).catch(function (response) {
                           $scope.error = puestaEnObraService.obtenerPrimerErrorModelState(response.data);
                           alert($scope.error);
                       });
                   } else {
                       alert("Error de conexión.");
                       /*try {
                           storage.set("supervision" + $scope.idSupervision, supervisionCompleta);
                           alert("Supervisión guardada correctamente.");
                       } catch (error) {
                           alert("Error al guardar la supervisión.");
                       }*/
                   }
               }
           }

           if ($scope.IsOnLine) {
               //Funcion para mostrar u ocultar el boton de guardar
               $scope.supervision = dataPersistService.getDatosSupervision();
               $scope.MostrarBotonGuardar = function () {
                   if ($scope.authentication.rol == "Consultor") {
                       if ($scope.supervision.Username.toUpperCase() != $scope.authentication.userName.toUpperCase()) {
                           return false;
                       }
                   }
                   return true;
               }
           } else {
               $scope.MostrarBotonGuardar = function () {
                   return true;
               }
           }

           $scope.Siguiente = function () {
               dataPersistService.savePuestaEnObra($scope.puestasObra);
               $location.path("/tomasmuestras/" + $scope.idSupervision);
           }

           $scope.Anterior = function () {
               dataPersistService.savePuestaEnObra($scope.puestasObra);
               $location.path("/materiales/" + $scope.idSupervision);
           }
       }]);