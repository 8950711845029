angular
    .module('climatologias')
    .factory('climatologiasService', ['$http', '$q', '__env', function climatologiasService($http, $q, __env) {
        var serviceUrl = __env.apiUrl;
        var climatologiasUrl = 'climatologias';

        function getClimatologiaPorSupervision(idSupervision) {
            return $http.get(serviceUrl + climatologiasUrl + '/' + idSupervision);
        }

        function informeSupervision(id) {
            return $http.get(serviceUrl + 'supervisiones/informe/' + id, { responseType: 'arraybuffer' });
        }

        function getCodigoSupervision(id) {
            return $http.get(serviceUrl + 'supervisiones/codigo/' + id);
        }

        function post(supervision) {
            //return $http.post(serviceUrl + 'supervisiones/registrar/', supervision);
            return $http({
                method: 'POST',
                url: serviceUrl + 'supervisiones/registrar',
                data: supervision,
                headers: { 'Content-Type': 'multipart/form-data' }
            })
        }

        function put(supervision) {
            //return $http.put(serviceUrl + 'supervisiones/editar', supervision);
            return $http({
                method: 'PUT',
                url: serviceUrl + 'supervisiones/editar',
                data: supervision,
                //headers: { 'Content-Type': 'application/json'}
                headers: { 'Content-Type': 'multipart/form-data' }
            })
        }

        function obtenerPrimerErrorModelState(response) {
            var errores = [];
            for (var key in response.ModelState) {
                for (var i = 0; i < response.ModelState[key].length; i++) {
                    errores.push(response.ModelState[key][i]);
                }
            }
            return errores[0];
        }

        return {
            getClimatologiaPorSupervision: getClimatologiaPorSupervision,
            informeSupervision: informeSupervision,
            getCodigoSupervision: getCodigoSupervision,
            post: post,
            put:put,
            obtenerPrimerErrorModelState: obtenerPrimerErrorModelState
        }
    }]);