angular.module('login', [])
       .controller('loginController', ['$scope', '$location', 'authenticationInfo', 'authenticationService', 'onlineStatus', 'dataPersistService', function ($scope, $location, authenticationInfo, authenticationService, onlineStatus, dataPersistService) {
           $scope.IsOnLine = onlineStatus.isOnline();
           $scope.authentication = authenticationInfo;
           $scope.usuario = authenticationInfo.userName;
           
           title = dataPersistService.getTextoCabecera();
           
           $scope.login = function () {
              
               return authenticationService.login({
                   userName: $scope.usuario,
                   password: $scope.password
               }).then(function () {
                   $scope.authentication = authenticationInfo;
               });
           }

           $scope.logout = function () {
               return authenticationService.logout();
           }

           $scope.modificarDatos = function () {
               $location.path('/usuario/config');
           }
       }]);
