angular.module('supervisiones', [])
       .controller('supervisionesController', ['$scope', '$location', 'authenticationInfo', 'supervisionesService', 'Utilidades', 'Excel', 'dataPersistService', 'onlineStatus', 'storage', function ($scope, $location, authenticationInfo, supervisionesService, Utilidades, Excel, dataPersistService, onlineStatus, storage) {
           $scope.authentication = authenticationInfo;
           dataPersistService.initializeSupervisionCompleta();
           dataPersistService.setTextoCabecera("");
           $scope.IsMobile = Utilidades.isMobileDevice();

           $scope.IsOnLine = onlineStatus.isOnline();

           $scope.agruparPorObra = function () {
               if ($scope.supervisiones != null) {
                   $scope.gruposPorObra = [];
                   var valorGrupo = "";
                   for (var i = 0 ; i < $scope.supervisiones.length ; i++) {
                       var supervision = $scope.supervisiones[i];
                       if (supervision.Obra !== valorGrupo) {
                           var grupoObra = {
                               obra: supervision.Obra,
                               supervisiones: []
                           };
                           valorGrupo = grupoObra.obra;
                           $scope.gruposPorObra.push(grupoObra);
                       }
                       grupoObra.supervisiones.push(supervision);
                   }
               }
           }

           $scope.toggleChevron = function (indexObra) {
               $('#chevronObra' + indexObra).toggleClass('glyphicon-chevron-down glyphicon-chevron-up');
           }

           //Si hay conexión recuperar supervisiones BD
           if ($scope.IsOnLine) {
               supervisionesService.getSupervisiones()
                                 .then(function (response) {
                                     var data = response.data;
                                     $scope.supervisiones = data;
                                     //Recuperar supervisiones locales
                                     var keys = storage.getKeys();
                                     if ($scope.supervisiones == null) {
                                         $scope.supervisiones = [];
                                     }
                                     for (var i = 0; i < keys.length; i++) {
                                         if (keys[i].indexOf("supervision") >= 0 && storage.get(keys[i]) != false) {
                                             var supervisionStorage = {
                                                 Id: storage.get(keys[i]).DatosSupervision.Id,
                                                 Obra: storage.get(keys[i]).DatosSupervision.Obra,
                                                 Fecha: Utilidades.getFecha(storage.get(keys[i]).DatosSupervision.Fecha),
                                                 Codigo: "CC-OBRA-LOTE-" + Utilidades.getLote(storage.get(keys[i]).DatosSupervision.NumeroLote) + "-0",
                                                 Usuario: "",
                                                 Informe: false
                                             }
                                             $scope.supervisiones.push(supervisionStorage);
                                         }
                                     }
                                     $scope.agruparPorObra();
                                 });
           } else {
               //Recuperar supervisiones locales
               var keys = storage.getKeys();
               if ($scope.supervisiones == null) {
                   $scope.supervisiones = [];
               }

               for (var i = 0; i < keys.length; i++) {
                   if (keys[i].indexOf("supervision") >= 0 && storage.get(keys[i]) != false) {
                       var supervisionStorage = {
                           Id: storage.get(keys[i]).DatosSupervision.Id,
                           Obra: storage.get(keys[i]).DatosSupervision.Obra,
                           Fecha: Utilidades.getFecha(storage.get(keys[i]).DatosSupervision.Fecha),
                           Codigo: "CC-OBRA-LOTE-" + Utilidades.getLote(storage.get(keys[i]).DatosSupervision.NumeroLote) + "-0",
                           Usuario: "",
                           Informe: false
                       }
                       $scope.supervisiones.push(supervisionStorage);
                   }
               }
               $scope.agruparPorObra();
           }

           $scope.informeSupervision = function (idSupervision) {
               supervisionesService.informeSupervision(idSupervision)
                .then(function (response) {
                    var data = response.data;
                    try {
                        var file = new Blob([data], { type: 'application/pdf' });
                    }
                    catch (e) {
                        // TypeError old chrome and FF
                        window.BlobBuilder = window.BlobBuilder ||
                                             window.WebKitBlobBuilder ||
                                             window.MozBlobBuilder ||
                                             window.MSBlobBuilder;
                        if (e.name == 'TypeError' && window.BlobBuilder) {
                            var bb = new BlobBuilder();
                            bb.append(data);
                            var file = bb.getBlob("application/pdf");
                        }
                        else if (e.name == "InvalidStateError") {
                            // InvalidStateError (tested on FF13 WinXP)
                            var file = new Blob([data], { type: "application/pdf" });
                        }
                        else {
                            // blob constructor unsupported entirely   
                        }
                    }

                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(file);
                    }
                    else {
                        var fileURL = URL.createObjectURL(file);
                        if ($scope.IsMobile) {
                            alert('Informe generado correctamente');
                            window.open(fileURL, "_blank", "location=yes");
                        } else {
                            window.open(fileURL, "_blank", "location=yes");
                        }
                    }
                }).catch(function (response) {
                       alert('Error al generar el informe');
                });
           }

           $scope.eliminarSupervision = function (idSupervision) {
               try {
                   storage.remove("supervision" + idSupervision);
                   for (var i = 0; i < $scope.supervisiones.length; i++) {
                       if ($scope.supervisiones[i].Id == idSupervision) {
                           $scope.supervisiones.splice(i, 1);
                           $scope.agruparPorObra();
                           break;
                       }
                   }
                   //$scope.supervisiones.splice($scope.supervisiones.indexOf(indice), 1);
                   alert("Supervisión eliminada");
               }catch (err){
                   alert("Error al eliminar supervisión");
               }
           }

           $scope.verSupervision = function (idSupervision) {
               $location.path('/supervisiones/' + idSupervision);

           };

           $scope.MostrarBotonInforme = function (userName, informe) {
               if (!($scope.IsOnLine)) return false;
               if (!(informe)) return false;
               if ($scope.authentication.rol == "Consultor") {
                   if (userName.toUpperCase() != authenticationInfo.userName.toUpperCase()) {
                       return false;
                   }
               }
               return true;
           }

           $scope.supervisionSincronizar = '';
           $scope.sincronizarLocales = function () {
               //Recuperar supervisiones locales
               var keys = storage.getKeys();
               for (var i = 0; i < keys.length; i++) {
                   if (keys[i].indexOf("supervision") >= 0 && storage.get(keys[i]) != false) {
                       $scope.supervisionSincronizar = keys[i];
                       //Se envía al servidor cada supervisión local
                       if(storage.get(keys[i]).DatosSupervision.Id>0){
                           //Modificar supervisión
                           //Este caso no debería darse, a no ser que se haya interrumpido la 
                           //conexión en medio de una edición online
                           supervisionesService.put(storage.get(keys[i]))
                               .then(function (response) {
                                   var data = response.data;
                                   storage.remove($scope.supervisionSincronizar);
                                   $scope.supervisionSincronizar = '';
                                   $scope.sincronizarLocales();
                               }).catch(function (response) {
                                   alert('Ha ocurrido un error y no se han podido guardar todas las supervisiones locales');
                               });
                       } else {
                           //Nueva supervisión
                           supervisionesService.post(storage.get(keys[i]))
                               .then(function (response) {
                                   var data = response.data;
                                   storage.remove($scope.supervisionSincronizar);
                                   $scope.supervisionSincronizar = '';
                                   $scope.sincronizarLocales();
                               }).catch(function (response) {
                                   alert('Ha ocurrido un error y no se han podido guardar todas las supervisiones locales');
                               });
                       }
                       break;
                   }
               }
               if ($scope.supervisionSincronizar == '') {
                   alert('Supervisiones locales guardadas correctamente');
               }
           }

           /*$scope.exportarExcel = function () {
               Excel.exportar('dvSupervisiones', 'tbSupervisiones', 'Supervisiones');
           };*/

       }]);

