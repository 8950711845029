angular.module('supervisiones')
       .controller('supervisionController', ['$scope', '$rootScope', '$routeParams', '$location', 'authenticationInfo', 'supervisionesService', 'Utilidades', 'dataPersistService', 'onlineStatus', 'storage', function ($scope, $rootScope, $routeParams, $location, authenticationInfo, supervisionesService, Utilidades, dataPersistService, onlineStatus, storage) {
           $scope.authentication = authenticationInfo;
           $scope.idSupervision = ($routeParams.id == null) ? 0 : $routeParams.id;
           $scope.supervision = dataPersistService.getDatosSupervision();
           if ($scope.supervision != null) {
               dataPersistService.setTextoCabecera("<span class='navbar-tit'>" + $scope.supervision.Obra + "</span><br><span class='navbar-subtit'>CC-OBRA-LOTE-" + Utilidades.getLote($scope.supervision.NumeroLote) + "-" + $scope.supervision.Version + "</span>");
               $rootScope.title = dataPersistService.getTextoCabecera();

           }
           $scope.dateOptions = Utilidades.getDatePickerOptions();

           $scope.IsOnLine = onlineStatus.isOnline();
           $scope.IsMobile = Utilidades.isMobileDevice();

           $scope.ObrasNumerosLotesLocalesActualizar = function () {
               if ($scope.obras == null) $scope.obras = [];
               //Recuperar obras de supervisiones locales
               var keys = storage.getKeys();
               var obraAlmacenada;
               for (var i = 0; i < keys.length; i++) {
                   if (keys[i].indexOf("supervision") >= 0 && storage.get(keys[i]) != false) {
                       //Se busca la obra de la supervisión local en el array de nombres de obras
                       obraAlmacenada = false;
                       for (var j = 0; j < $scope.obras.length; j++) {
                           if (storage.get(keys[i]).DatosSupervision.Obra == $scope.obras[j].label) {
                               //Existe la obra, se actualiza su número de lote
                               obraAlmacenada = true;
                               if ($scope.obras[j].MaxNumeroLote < storage.get(keys[i]).DatosSupervision.NumeroLote) {
                                   $scope.obras[j].MaxNumeroLote = storage.get(keys[i]).DatosSupervision.NumeroLote;
                               }
                               break;
                           }
                       }
                        if(!(obraAlmacenada)){
                            //No existe la obra, se da de alta
                            var obra = {
                                label: storage.get(keys[i]).DatosSupervision.Obra,
                                MaxNumeroLote: storage.get(keys[i]).DatosSupervision.NumeroLote
                        }
                            $scope.obras.push(obra);
                       }
                   }
               }

           }

           $scope.AutocompletarObra = function () {
               $('#Obra').autocomplete({
                   source: $scope.obras,
                   focus: function (event, ui) {
                       $("#Obra").val(ui.item.label);
                       return false;
                   },
                   select: function (event, ui) {
                       $scope.supervision.Obra = ui.item.label;
                       $scope.$apply();
                       event.preventDefault();
                       $scope.NumeroLoteActualizar();
                       return false;
                   },
                   change: function (event, ui) {
                       if (ui.item != null) {
                           $scope.supervision.Obra = ui.item.label;
                           $scope.$apply();
                           event.preventDefault();
                           $scope.NumeroLoteActualizar();
                           return false;
                       } else {
                           $scope.NumeroLoteActualizar();
                       }
                   }
               });
           }

           $scope.AutocompletarSupervisores = function () {
               $('#supervisor').autocomplete({
                   source: $scope.supervisores,
                   focus: function (event, ui) {
                       $("#supervisor").val(ui.item.label);
                       return false;
                   },
                   select: function (event, ui) {
                       $scope.supervision.Supervisor = ui.item.label;
                       $scope.$apply();
                       event.preventDefault();
                       return false;
                   },
                   change: function (event, ui) {
                       if (ui.item != null) {
                           $scope.supervision.Supervisor = ui.item.label;
                           $scope.$apply();
                           event.preventDefault();
                           return false;
                       }
                   }
               });
           }

           if ($scope.IsOnLine) {
               if ($scope.supervision == null) {
                   if ($routeParams.id > 0) {
                       supervisionesService.getSupervision($routeParams.id)
                                         .then(function (response) {
                                             var data = response.data;
                                             $scope.supervision = data;
                                             dataPersistService.setTextoCabecera("<span class='navbar-tit'>" + $scope.supervision.Obra + "</span><br><span class='navbar-subtit'>CC-OBRA-LOTE-" + Utilidades.getLote($scope.supervision.NumeroLote) + "-" + $scope.supervision.Version + "</span>");
                                            $rootScope.title = dataPersistService.getTextoCabecera();

                                         });
                   } else if ($routeParams.id < 0) {
                       //Supervisiones locales (con Id negativo)
                       dataPersistService.initializeSupervisionCompleta();
                       var keys = storage.getKeys();
                       for (var i = 0; i < keys.length; i++) {
                           if (keys[i].indexOf("supervision") >= 0 && storage.get(keys[i]) != false) {
                               if (storage.get(keys[i]).DatosSupervision.Id == $routeParams.id) {
                                   dataPersistService.saveSupervisionCompleta(storage.get(keys[i]));
                                   $scope.supervision = dataPersistService.getDatosSupervision();
                                   dataPersistService.setTextoCabecera("<span class='navbar-tit'>" + $scope.supervision.Obra + "</span><br><span class='navbar-subtit'>CC-OBRA-LOTE-" + Utilidades.getLote($scope.supervision.NumeroLote) + "-" + $scope.supervision.Version + "</span>");
                                    $rootScope.title = dataPersistService.getTextoCabecera();
                                   break;
                               }
                           }
                       }
                   }
               }
               supervisionesService.getObrasSupervisiones()
                              .then(function (response) {
                                  var data = response.data;
                                  $scope.obras = data;
                                  $scope.ObrasNumerosLotesLocalesActualizar();
                                  storage.set("obras", $scope.obras);
                                  $scope.AutocompletarObra();
                              });
               supervisionesService.getSupervisoresSupervisiones()
                              .then(function (response) {
                                  var data = response.data;
                                  $scope.supervisores = data;
                                  storage.set("supervisores", $scope.supervisores);
                                  $scope.AutocompletarSupervisores();
                              });
           } else {
               if ($scope.supervision == null) {
                   dataPersistService.initializeSupervisionCompleta();
                   if ($routeParams.id < 0) {
                       //Supervisión local (con Id negativo)
                       var keys = storage.getKeys();
                       for (var i = 0; i < keys.length; i++) {
                           if (keys[i].indexOf("supervision") >= 0 && storage.get(keys[i]) != false) {
                               if (storage.get(keys[i]).DatosSupervision.Id == $routeParams.id) {
                                   dataPersistService.saveSupervisionCompleta(storage.get(keys[i]));
                                   $scope.supervision = dataPersistService.getDatosSupervision();
                                   dataPersistService.setTextoCabecera("<span class='navbar-tit'>" + $scope.supervision.Obra + "</span><br><span class='navbar-subtit'>CC-OBRA-LOTE-" + Utilidades.getLote($scope.supervision.NumeroLote) + "-" + $scope.supervision.Version + "</span>");
                                    $rootScope.title = dataPersistService.getTextoCabecera();
                                   break;
                               }
                           } else if (keys[i].indexOf("obras") >= 0 && storage.get(keys[i]) != false) {
                               $scope.obras = storage.get(keys[i]);
                               $scope.ObrasNumerosLotesLocalesActualizar();
                               storage.set("obras", $scope.obras);
                               $scope.AutocompletarObra();
                           } else if (keys[i].indexOf("supervisores") >= 0 && storage.get(keys[i]) != false) {
                               $scope.supervisores = storage.get(keys[i]);
                               $scope.AutocompletarSupervisores();
                           }
                       }
                   }else{
                       //Nueva supervisión local
                       var minIdStorageSupervisiones = 0;
                       var idActual;
                       var keys = storage.getKeys();
                       for (var i = 0; i < keys.length; i++) {
                           if (keys[i].indexOf("supervision") >= 0 && storage.get(keys[i]) != false) {
                               idActual = keys[i].substring("supervision".length);
                               if (parseFloat(idActual) < parseFloat(minIdStorageSupervisiones)) {
                                   minIdStorageSupervisiones = idActual;
                               }
                           } else if (keys[i].indexOf("obras") >= 0 && storage.get(keys[i]) != false) {
                               $scope.obras = storage.get(keys[i]);
                               $scope.ObrasNumerosLotesLocalesActualizar();
                               storage.set("obras", $scope.obras);
                               $scope.AutocompletarObra();
                           } else if (keys[i].indexOf("supervisores") >= 0 && storage.get(keys[i]) != false) {
                               $scope.supervisores = storage.get(keys[i]);
                               $scope.AutocompletarSupervisores();
                           }
                       }
                       $scope.idSupervision = 0 - Math.abs(minIdStorageSupervisiones) - 1;
                   }
               }
           }

           $scope.NumeroLoteActualizar = function () {
               $scope.supervision.NumeroLote = 1;
               if ($scope.supervision.Version == null) $scope.supervision.Version = 0;
               var encontrada = false;
               for (var i = 0; i < $scope.obras.length; i++) {
                   if ($scope.supervision.Obra == $scope.obras[i].label) {
                       encontrada = true;
                       $scope.supervision.NumeroLote = $scope.obras[i].MaxNumeroLote + 1;
                       dataPersistService.setTextoCabecera("<span class='navbar-tit'>" + $scope.supervision.Obra + "</span><br><span class='navbar-subtit'>CC-OBRA-LOTE-" + Utilidades.getLote($scope.supervision.NumeroLote) + "-" + $scope.supervision.Version + "</span>");
                       $rootScope.title = dataPersistService.getTextoCabecera();
                       break;
                   }
               }
               if (!(encontrada)) {
                   dataPersistService.setTextoCabecera("<span class='navbar-tit'>" + $scope.supervision.Obra + "</span><br><span class='navbar-subtit'>CC-OBRA-LOTE-" + Utilidades.getLote($scope.supervision.NumeroLote) + "-" + $scope.supervision.Version + "</span>");
                   $rootScope.title = dataPersistService.getTextoCabecera();
               }
           }

           //Menú superior
           $scope.ClimatologiaCargar = function () {
               dataPersistService.saveDatosSupervision($scope.supervision);
               $location.path("/climatologias/" + $scope.idSupervision);
           }
           $scope.PreparacionSuperficieCargar = function () {
               dataPersistService.saveDatosSupervision($scope.supervision);
               $location.path("/preparacionessuperficie/" + $scope.idSupervision);
           }
           $scope.MaterialesCargar = function () {
               dataPersistService.saveDatosSupervision($scope.supervision);
               $location.path("/materiales/" + $scope.idSupervision);
           }
           $scope.PuestasObraCargar = function () {
               dataPersistService.saveDatosSupervision($scope.supervision);
               $location.path("/puestasobra/" + $scope.idSupervision);
           }
           $scope.TomasMuestrasCargar = function () {
               dataPersistService.saveDatosSupervision($scope.supervision);
               $location.path("/tomasmuestras/" + $scope.idSupervision);
           }
           //Fin menú superior

           $scope.Informe = function () {
               supervisionesService.informeSupervision($scope.idSupervision)
                .then(function (response) {
                    var data = response.data;
                    try {
                        var file = new Blob([data], { type: 'application/pdf' });
                    }
                    catch (e) {
                        // TypeError old chrome and FF
                        window.BlobBuilder = window.BlobBuilder ||
                                             window.WebKitBlobBuilder ||
                                             window.MozBlobBuilder ||
                                             window.MSBlobBuilder;
                        if (e.name == 'TypeError' && window.BlobBuilder) {
                            var bb = new BlobBuilder();
                            bb.append([data]);
                            var file = bb.getBlob("application/pdf");
                        }
                        else if (e.name == "InvalidStateError") {
                            // InvalidStateError (tested on FF13 WinXP)
                            var file = new Blob([data], { type: "application/pdf" });
                        }
                        else {
                            // blob constructor unsupported entirely   
                        }
                    }

                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(file);
                    }
                    else {
                        var fileURL = URL.createObjectURL(file);
                        if ($scope.IsMobile) {
                            alert('Informe generado correctamente');
                            window.open(fileURL, "_blank", "location=yes");
                        } else {
                            window.open(fileURL, "_blank", "location=yes");
                        }
                    }
                }).catch(function (response) {
                    alert('Error al generar el informe');
                });
           }

           $scope.Guardar = function () {
               $scope.supervision.Id = $scope.idSupervision;
               dataPersistService.saveDatosSupervision($scope.supervision);
               var supervisionCompleta = dataPersistService.getSupervisionCompleta();
               if ($scope.idSupervision < 1) {
                   if ($scope.IsOnLine) {
                       supervisionesService.post(supervisionCompleta).then(function (response) {
                           var data = response.data;
                           storage.remove("supervision" + $scope.idSupervision);
                           $scope.idSupervision = data;
                           $scope.supervision.Id = data;
                           alert("Supervisión creada correctamente.");
                           dataPersistService.saveDatosSupervision(null);
                           supervisionesService.getSupervision($scope.idSupervision)
                                             .then(function (response) {
                                                 var data = response.data;
                                                 $scope.supervision = data;
                                                 dataPersistService.setTextoCabecera("<span class='navbar-tit'>" + $scope.supervision.Obra + "</span><br><span class='navbar-subtit'>CC-OBRA-LOTE-" + Utilidades.getLote($scope.supervision.NumeroLote) + "-" + $scope.supervision.Version + "</span>");
                                                 $rootScope.title = dataPersistService.getTextoCabecera();
                                             });
                       }).catch(function (response) {
                           $scope.error = supervisionesService.obtenerPrimerErrorModelState(response.data);
                           alert($scope.error);
                       });
                   } else {
                       try {
                           $scope.supervision.Id = $scope.idSupervision;
                           $scope.supervision.Version = 0;
                           storage.set("supervision" + $scope.supervision.Id, supervisionCompleta);
                           alert("Supervisión creada correctamente.");
                       }catch(error){
                           alert("Error al crear la supervisión.");
                       }
                   }
               } else {
                   if ($scope.IsOnLine) {
                       supervisionesService.put(supervisionCompleta).then(function (response) {
                           var data = response.data;
                           alert(data);
                           dataPersistService.saveDatosSupervision(null);
                           supervisionesService.getCodigoSupervision($scope.idSupervision)
                                             .then(function (response) {
                                                 var data = response.data;
                                                 dataPersistService.setTextoCabecera("<span class='navbar-tit'>" + data[0] + "</span><br><span class='navbar-subtit'>" + data[1] + "</span>");
                                                 $rootScope.title = dataPersistService.getTextoCabecera();
                                             });
                            //$location.path("/supervisiones");
                       }).catch(function (response) {
                           $scope.error = supervisionesService.obtenerPrimerErrorModelState(response.data);
                           alert($scope.error);
                       });
                   } else {
                       alert("Error de conexión.");
                       /*try{
                           $scope.supervision.Id = $scope.idSupervision;
                           storage.set("supervision" + $scope.supervision.Id, supervisionCompleta);
                           alert("Supervisión guardada correctamente.");
                       } catch (error) {
                           alert("Error al guardar la supervisión.");
                       }*/
                   }
               }
           }

           $scope.Siguiente = function () {
               dataPersistService.saveDatosSupervision($scope.supervision);
               $location.path("/climatologias/" + $scope.idSupervision);
           }

           $scope.MostrarBotonGuardar = function () {
               if ($scope.authentication.rol == "Consultor") {
                   if ($scope.supervision.Username.toUpperCase() != authenticationInfo.userName.toUpperCase()) {
                       return false;
                   }
               } 
               return true;
           }
       }]);
