angular.module('usuariosAdmin')
       .controller('nuevoUsuarioAdminController', ['$scope', '$routeParams', '$location', 'usuariosAdminService', function ($scope, $routeParams, $location, usuariosAdminService) {
           $scope.error = '';
           $scope.rolUsuario = 2;

           usuariosAdminService.getRoles()
                        .then(function (response) {
                            var data = response.data;
                            $scope.roles = data;
                        });

           $scope.volver = function () {
               $location.path("/admin/usuarios");
           };

           $scope.guardarUsuario = function () {
               usuariosAdminService.post($scope.usuario, $scope.rolUsuario).then(function (response) {
                   var data = response.data;
                   alert(data);
                   $location.path("/admin/usuarios");
               }).catch(function (response) {
                   $scope.error = usuariosAdminService.obtenerPrimerErrorModelState(response.data);
                   alert($scope.error);
               });
           }
       }]);

