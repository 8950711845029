angular.module('usuariosAdmin', [])
       .controller('usuariosController', ['$scope', '$location', 'usuariosAdminService', 'Excel', function ($scope, $location, usuariosAdminService, Excel) {
           usuariosAdminService.getUsuarios()
                             .then(function (response) {
                                var data = response.data;
                                 $scope.usuarios = data;
                             });
           $scope.verUsuario = function (idUsuario) {
               $location.path('/admin/usuarios/' + idUsuario);
           };

           $scope.nuevoUsuario = function (idUsuario) {
                   $location.path('/admin/usuarios/nuevo/');
           };

           $scope.usuariosSeleccionados = "";
           $scope.eliminarUsuarios = function () {
               angular.forEach($scope.usuarios, function (usuario) {
                   if (usuario.Selected) {
                       if ($scope.usuariosSeleccionados != "") {
                           $scope.usuariosSeleccionados = $scope.usuariosSeleccionados + "," + usuario.Id
                       } else {
                           $scope.usuariosSeleccionados = usuario.Id
                       }

                   };
               });
               usuariosAdminService.eliminarUsuarios($scope.usuariosSeleccionados).then(function (response) {
                   var data = response.data;
                   alert(data);
                   $scope.usuariosSeleccionados = [];
                   usuariosAdminService.getUsuarios()
                           .then(function (response) {
                                var data = response.data;
                               $scope.usuarios = data;
                           });
               }).catch(function (response) {
                   $scope.error = usuariosAdminService.obtenerPrimerErrorModelState(response.data);
                   alert($scope.error);
               });
           };

           $scope.exportarExcel = function () {
               Excel.exportar('dvUsuarios', 'tbUsuarios', 'Usuarios');
           };

           $scope.seleccionarTodos = function () {
               if (!$scope.SeleccionadosTodos) {
                   $scope.SeleccionadosTodos = true;
               } else {
                   $scope.SeleccionadosTodos = false;
               }
               angular.forEach($scope.usuarios, function (usuario) {
                   usuario.Selected = $scope.SeleccionadosTodos;
               });

           };

       }]);

