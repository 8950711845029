angular
    .module('puestaEnObra')
    .factory('puestaEnObraService', ['$http', '$q', '__env', '$cordovaFile', '$cordovaFileTransfer', function puestaEnObraService($http, $q, __env, $cordovaFile, $cordovaFileTransfer) {
        var serviceUrl = __env.apiUrl;
        var puestasEnObraUrl = 'puestaenobra';

        function getPuestaEnObra(idSupervision) {
            return $http.get(serviceUrl + puestasEnObraUrl + '/' + idSupervision);
        }

        function informeSupervision(id) {
            return $http.get(serviceUrl + 'supervisiones/informe/' + id, { responseType: 'arraybuffer' });
        }

        function getCodigoSupervision(id) {
            return $http.get(serviceUrl + 'supervisiones/codigo/' + id);
        }

        function post(supervision) {
            return $http({
                method: 'POST',
                url: serviceUrl + 'supervisiones/registrar',
                data: supervision,
                headers: { 'Content-Type': 'multipart/form-data' }
            })
        }

        function put(supervision) {
            return $http({
                method: 'PUT',
                url: serviceUrl + 'supervisiones/editar',
                data: supervision,
                headers: { 'Content-Type': 'multipart/form-data' }
            })
        }

        function obtenerPrimerErrorModelState(response) {
            var errores = [];
            for (var key in response.ModelState) {
                for (var i = 0; i < response.ModelState[key].length; i++) {
                    errores.push(response.ModelState[key][i]);
                }
            }
            return errores[0];
        }

        return {
            getPuestaEnObra: getPuestaEnObra,
            informeSupervision: informeSupervision,
            getCodigoSupervision: getCodigoSupervision,
            post: post,
            put: put,
            obtenerPrimerErrorModelState: obtenerPrimerErrorModelState,
        }
    }]);