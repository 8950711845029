angular.module('preparacionesSuperficie', [])
       .controller('preparacionSuperficieController', ['$scope', '$rootScope', '$routeParams', '$location', 'authenticationInfo', 'preparacionesSuperficiesService', 'dataPersistService', 'onlineStatus', 'storage', 'Utilidades'/*, '$timeout', '$cordovaCamera'*/, '$cordovaFile', '$cordovaFileTransfer'/*, '$cordovaActionSheet'*/, function ($scope, $rootScope, $routeParams, $location, authenticationInfo, preparacionesSuperficiesService, dataPersistService, onlineStatus, storage, Utilidades/*, $timeout, $cordovaCamera*/, $cordovaFile, $cordovaFileTransfer/*, $cordovaActionSheet*/) {
           $scope.authentication = authenticationInfo;
           $scope.idSupervision = ($routeParams.id == null) ? 0 : $routeParams.id;
           $scope.preparacionSuperficie = dataPersistService.getPreparacionSuperficie();

           $scope.IsOnLine = onlineStatus.isOnline();
           $scope.IsMobile = Utilidades.isMobileDevice();

           var titulo = dataPersistService.getTextoCabecera();
           if (titulo != null && titulo != "" && titulo.length > 0) {
               $rootScope.title = titulo;
           }

           if ($scope.IsOnLine) {
               if ($scope.preparacionSuperficie == null) {
                   if ($routeParams.id > 0) {
                       preparacionesSuperficiesService.getPreparacionSuperficiePorSupervision($routeParams.id)
                                         .then(function (response) {
                                             var data = response.data;
                                             $scope.preparacionSuperficie = data;
                                         });
                   }
               }
           }

           $scope.uploadFile = function (input) {
                   if (input.files && input.files[0]) {
                       var reader = new FileReader();
                       reader.onload = $scope.imageIsLoaded;
                       $scope.imageName = input.files[0].name;
                       reader.readAsDataURL(input.files[0]);
                   }
           };
           $scope.imageIsLoaded = function (e) {
               $scope.$apply(function () {
                   $('#imagenSeleccionada').attr('src', e.target.result);
                   $scope.preparacionSuperficie.Imagen = e.target.result.replace(/data:image\/jpeg;base64,/g, '');
                   $scope.preparacionSuperficie.NombreImagen = $scope.imageName;
                   $scope.imageName = null;

                   if ($scope.preparacionSuperficie == null) {
                       $scope.preparacionSuperficie = {
                           Id: 0,
                           PropuestaSenyalizacionObraEntregada: null,
                           ObservacionesPropuestaSenyalizacionObraEntregada: null,
                           TramoSenyalizacionSegunPropuesta: null,
                           ObservacionesTramoSenyalizacionSegunPropuesta: null,
                           SuperficieLimpia: null,
                           ObservacionesSuperficieLimpia: null,
                           FresadoPrevio: null,
                           ObservacionesFresadoPrevio: null,
                           EjecucionRiegoAdherencia: null,
                           ObservacionesEjecucionRiegoAdherencia: null,
                           Imagen: null,
                           NombreImagen: null
                       }
                   }
               });
           }

           //Menú superior
           $scope.DatosSupervisionCargar = function () {
               dataPersistService.savePreparacionSuperficie($scope.preparacionSuperficie);
               $location.path("/supervisiones/" + $scope.idSupervision);
           }
           $scope.ClimatologiaCargar = function () {
               dataPersistService.savePreparacionSuperficie($scope.preparacionSuperficie);
               $location.path("/climatologias/" + $scope.idSupervision);
           }
           $scope.MaterialesCargar = function () {
               dataPersistService.savePreparacionSuperficie($scope.preparacionSuperficie);
               $location.path("/materiales/" + $scope.idSupervision);
           }
           $scope.PuestasObraCargar = function () {
               dataPersistService.savePreparacionSuperficie($scope.preparacionSuperficie);
               $location.path("/puestasobra/" + $scope.idSupervision);
           }
           $scope.TomasMuestrasCargar = function () {
               dataPersistService.savePreparacionSuperficie($scope.preparacionSuperficie);
               $location.path("/tomasmuestras/" + $scope.idSupervision);
           }
           //Fin menú superior

           $scope.Informe = function () {
               preparacionesSuperficiesService.informeSupervision($scope.idSupervision)
                .then(function (response) {
                    var data = response.data;
                    try {
                        var file = new Blob([data], { type: 'application/pdf' });
                    }
                    catch (e) {
                        // TypeError old chrome and FF
                        window.BlobBuilder = window.BlobBuilder ||
                                             window.WebKitBlobBuilder ||
                                             window.MozBlobBuilder ||
                                             window.MSBlobBuilder;
                        if (e.name == 'TypeError' && window.BlobBuilder) {
                            var bb = new BlobBuilder();
                            bb.append([data]);
                            var file = bb.getBlob("application/pdf");
                        }
                        else if (e.name == "InvalidStateError") {
                            // InvalidStateError (tested on FF13 WinXP)
                            var file = new Blob([data], { type: "application/pdf" });
                        }
                        else {
                            // blob constructor unsupported entirely   
                        }
                    }

                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(file);
                    }
                    else {
                        var fileURL = URL.createObjectURL(file);
                        if ($scope.IsMobile) {
                            alert('Informe generado correctamente');
                            window.open(fileURL, "_blank", "location=yes");
                        } else {
                            window.open(fileURL, "_blank", "location=yes");
                        }
                    }
                }).catch(function (response) {
                    alert('Error al generar el informe');
                });
           }

           $scope.Guardar = function () {
               $scope.preparacionSuperficie.SupervisionId = $scope.idSupervision;
               dataPersistService.savePreparacionSuperficie($scope.preparacionSuperficie);
               var supervisionCompleta = dataPersistService.getSupervisionCompleta();
               if ($scope.idSupervision < 1) {
                   if ($scope.IsOnLine) {
                       preparacionesSuperficiesService.post(supervisionCompleta).then(function (response) {
                           var data = response.data;
                           $scope.idSupervision = data;
                           $scope.preparacionSuperficie.SupervisionId = data;
                           alert("Supervisión creada correctamente.");
                           dataPersistService.savePreparacionSuperficie(null);
                           preparacionesSuperficiesService.getPreparacionSuperficiePorSupervision($scope.idSupervision)
                                             .then(function (response) {
                                                 var data = response.data;
                                                 $scope.preparacionSuperficie = data;
                                                 //upload($scope.picFile);
                                             });
                       }).catch(function (response) {
                           $scope.error = preparacionesSuperficiesService.obtenerPrimerErrorModelState(response.data);
                           alert($scope.error);
                       });
                   } else {
                       supervisionCompleta.DatosSupervision.Id = $scope.idSupervision;
                       try {
                           storage.set("supervision" + $scope.idSupervision, supervisionCompleta);
                           alert("Supervisión creada correctamente.");
                       } catch (error) {
                           alert("Error al crear la supervisión.");
                       }
                   }
               } else {
                   if ($scope.IsOnLine) {
                       preparacionesSuperficiesService.put(supervisionCompleta).then(function (response) {
                           var data = response.data;
                           alert(data);
                           dataPersistService.savePreparacionSuperficie(null);
                           preparacionesSuperficiesService.getCodigoSupervision($scope.idSupervision)
                                     .then(function (response) {
                                         var data = response.data;
                                         dataPersistService.setTextoCabecera("<span class='navbar-tit'>" + data[0] + "</span><br><span class='navbar-subtit'>" + data[1] + "</span>");
                                         $rootScope.title = dataPersistService.getTextoCabecera();
                                     });
                       }).catch(function (response) {
                           $scope.error = preparacionesSuperficiesService.obtenerPrimerErrorModelState(response.data);
                           alert($scope.error);
                       });
                   } else {
                       alert("Error de conexión.");
                       /*try {
                           storage.set("supervision" + $scope.idSupervision, supervisionCompleta);
                           alert("Supervisión guardada correctamente.");
                       } catch (error) {
                           alert("Error al guardar la supervisión.");
                       }*/
                   }
               }
           }

           if ($scope.IsOnLine) {
               //Funcion para mostrar u ocultar el boton de guardar
               $scope.supervision = dataPersistService.getDatosSupervision();
               $scope.MostrarBotonGuardar = function () {
                   if ($scope.authentication.rol == "Consultor") {
                       if ($scope.supervision.Username.toUpperCase() != $scope.authentication.userName.toUpperCase()) {
                           return false;
                       }
                   }
                   return true;
               }
           } else {
               $scope.MostrarBotonGuardar = function () {
                   return true;
               }
           }

            $scope.Siguiente = function () {
                dataPersistService.savePreparacionSuperficie($scope.preparacionSuperficie);
                $location.path("/materiales/" + $scope.idSupervision);
            }

            $scope.Anterior = function () {
                dataPersistService.savePreparacionSuperficie($scope.preparacionSuperficie);
                $location.path("/climatologias/" + $scope.idSupervision);
            }
            
       }]);
