angular.module('alertasAdmin', [])
       .controller('alertasController', ['$scope', '$location', 'alertasAdminService', function ($scope, $location, alertasAdminService) {
           alertasAdminService.getDatosObras()
                             .then(function (response) {
                                var data = response.data;
                                 $scope.obras = data;
                             });
           $scope.verUsuarios = function (obra) {
               $location.path('/admin/alertas/usuarios/' + obra);
           }
       }]);
