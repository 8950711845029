angular.module('tomaDeMuestras', [])
       .controller('tomaDeMuestrasController', ['$scope', '$rootScope', '$routeParams', '$location', 'authenticationInfo', 'tomaDeMuestrasService', 'dataPersistService', 'onlineStatus', 'storage', 'Utilidades', '$cordovaFile', '$cordovaFileTransfer', function ($scope, $rootScope, $routeParams, $location, authenticationInfo, tomaDeMuestrasService, dataPersistService, onlineStatus, storage, Utilidades, $cordovaFile, $cordovaFileTransfer) {
           $scope.authentication = authenticationInfo;
           $scope.idSupervision = ($routeParams.id == null) ? 0 : $routeParams.id;
           $scope.tomaDeMuestras = dataPersistService.getTomaDeMuestras();
           $scope.IsOnLine = onlineStatus.isOnline();
           $scope.IsMobile = Utilidades.isMobileDevice();

           $scope.dateOptions = Utilidades.getDatePickerOptions();

           var titulo = dataPersistService.getTextoCabecera();
           if (titulo != null && titulo != "" && titulo.length > 0) {
             $rootScope.title = titulo;
           }

           if ($scope.IsOnLine) {
               if ($scope.tomaDeMuestras.Id == null) {
                   if ($routeParams.id > 0) {
                       tomaDeMuestrasService.getTomaDeMuestras($routeParams.id)
                                         .then(function (response) {
                                             var data = response.data;
                                             if (data == null) {
                                                 dataPersistService.initializeTomaDeMuestras();
                                             } else {
                                                 $scope.tomaDeMuestras = data;
                                             }
                                         });
                   }
               }
           }

           $scope.$on('LastRepeaterElement', function () {
            if (!$scope.added) {
              $('.panel .collapse').collapse("hide");
            }
            $scope.added = false;
          });

           $scope.inputID = "";
           $scope.uploadFile = function (input) {
               if (input.files && input.files[0]) {
                   var reader = new FileReader();
                   $scope.inputID = input.id;
                   reader.onload = $scope.imageIsLoaded;
                   $scope.imageName= input.files[0].name;
                   reader.readAsDataURL(input.files[0]);
               }
           };
           $scope.imageIsLoaded = function (e) {
               $scope.$apply(function () {
                   if ($scope.inputID.indexOf("photo-upload_ra") >= 0) {
                       var index = parseInt($scope.inputID.substring("photo-upload_ra".length));

                       $('#imagenSeleccionada_ra' + index).attr('src', e.target.result);

                       $scope.tomaDeMuestras.RiegosAdherencia[index].Imagen = e.target.result.replace(/data:image\/jpeg;base64,/g, '');
                       $scope.tomaDeMuestras.RiegosAdherencia[index].NombreImagen = $scope.imageName;
                       $scope.imageName = null;
                   }
                   if ($scope.inputID.indexOf("photo-upload_cr") >= 0) {
                       var index = parseInt($scope.inputID.substring("photo-upload_cr".length));

                       $('#imagenSeleccionada_cr' + index).attr('src', e.target.result);

                       $scope.tomaDeMuestras.CapasRodadura[index].Imagen = e.target.result.replace(/data:image\/jpeg;base64,/g, '');
                       $scope.tomaDeMuestras.CapasRodadura[index].NombreImagen = $scope.imageName;
                       $scope.imageName = null;
                   }
                   if ($scope.inputID.indexOf("photo-upload_t") >= 0) {
                       var index = parseInt($scope.inputID.substring("photo-upload_t".length));

                       $('#imagenSeleccionada_t' + index).attr('src', e.target.result);

                       $scope.tomaDeMuestras.Testigos[index].Imagen = e.target.result.replace(/data:image\/jpeg;base64,/g, '');
                       $scope.tomaDeMuestras.Testigos[index].NombreImagen = $scope.imageName;
                       $scope.imageName = null;
                   }
                   if ($scope.inputID.indexOf("photo-upload_m") >= 0) {
                       var index = parseInt($scope.inputID.substring("photo-upload_m".length));

                       $('#imagenSeleccionada_m' + index).attr('src', e.target.result);

                       $scope.tomaDeMuestras.Macrotexturas[index].Imagen = e.target.result.replace(/data:image\/jpeg;base64,/g, '');
                       $scope.tomaDeMuestras.Macrotexturas[index].NombreImagen = $scope.imageName;
                       $scope.imageName = null;
                   }
               });
           }

           //Menú superior
           $scope.DatosSupervisionCargar = function () {
               dataPersistService.saveTomaDeMuestras($scope.tomaDeMuestras);
               $location.path("/supervisiones/" + $scope.idSupervision);
           }
           $scope.ClimatologiaCargar = function () {
               dataPersistService.saveTomaDeMuestras($scope.tomaDeMuestras);
               $location.path("/climatologias/" + $scope.idSupervision);
           }
           $scope.PreparacionSuperficieCargar = function () {
               dataPersistService.saveTomaDeMuestras($scope.tomaDeMuestras);
               $location.path("/preparacionessuperficie/" + $scope.idSupervision);
           }
           $scope.MaterialesCargar = function () {
               dataPersistService.saveTomaDeMuestras($scope.tomaDeMuestras);
               $location.path("/materiales/" + $scope.idSupervision);
           }
           $scope.PuestasObraCargar = function () {
               dataPersistService.saveTomaDeMuestras($scope.tomaDeMuestras);
               $location.path("/puestasobra/" + $scope.idSupervision);
           }
           //Fin menú superior

           $scope.Informe = function () {
               tomaDeMuestrasService.informeSupervision($scope.idSupervision)
                .then(function (response) {
                    var data = response.data;
                    try {
                        var file = new Blob([data], { type: 'application/pdf' });
                    }
                    catch (e) {
                        // TypeError old chrome and FF
                        window.BlobBuilder = window.BlobBuilder ||
                                             window.WebKitBlobBuilder ||
                                             window.MozBlobBuilder ||
                                             window.MSBlobBuilder;
                        if (e.name == 'TypeError' && window.BlobBuilder) {
                            var bb = new BlobBuilder();
                            bb.append([data]);
                            var file = bb.getBlob("application/pdf");
                        }
                        else if (e.name == "InvalidStateError") {
                            // InvalidStateError (tested on FF13 WinXP)
                            var file = new Blob([data], { type: "application/pdf" });
                        }
                        else {
                            // blob constructor unsupported entirely   
                        }
                    }

                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(file);
                    }
                    else {
                        var fileURL = URL.createObjectURL(file);
                        if ($scope.IsMobile) {
                            alert('Informe generado correctamente');
                            window.open(fileURL, "_blank", "location=yes");
                        } else {
                            window.open(fileURL, "_blank", "location=yes");
                        }
                    }
                }).catch(function (response) {
                    alert('Error al generar el informe');
                });
           }

           $scope.NuevoRiegoAdherencia = function () {
              $scope.added = true;
               if ($scope.tomaDeMuestras.RiegosAdherencia == null) {
                   $scope.tomaDeMuestras.RiegosAdherencia = [];
               }
               var riegoAdherencia = {
                   Id: 0 - $scope.tomaDeMuestras.RiegosAdherencia.length,
                   NumeroProbeta: $scope.tomaDeMuestras.RiegosAdherencia.length + 1,
                   Fecha: null,
                   Hora: null,
                   Localizacion: null,
                   Observaciones: null,
                   Imagen: null,
                   NombreImagen: null,
               };
               $scope.tomaDeMuestras.RiegosAdherencia.push(riegoAdherencia);
           }
           $scope.CopiarRiegoAdherenciaAnterior = function () {
               $scope.added = true;
               var riegoAdherenciaAnterior = $scope.tomaDeMuestras.RiegosAdherencia[$scope.tomaDeMuestras.RiegosAdherencia.length - 1];
               var riegoAdherencia = {
                   Id: 0 - $scope.tomaDeMuestras.RiegosAdherencia.length,
                   NumeroProbeta: $scope.tomaDeMuestras.RiegosAdherencia.length + 1,
                   Fecha: riegoAdherenciaAnterior.Fecha,
                   Hora: riegoAdherenciaAnterior.Hora,
                   Localizacion: riegoAdherenciaAnterior.Localizacion,
                   Observaciones: null,
                   Imagen: null,
                   NombreImagen: null,
               }
               $scope.tomaDeMuestras.RiegosAdherencia.push(riegoAdherencia);
           }
           $scope.EliminarRiegoAdherencia = function (riegoAdherenciaIndex) {
               $scope.tomaDeMuestras.RiegosAdherencia.splice($scope.tomaDeMuestras.RiegosAdherencia.indexOf(riegoAdherenciaIndex), 1);
           }
           $scope.NuevaCapaRodadura = function () {
              $scope.added = true;

               if ($scope.tomaDeMuestras.CapasRodadura == null) {
                   $scope.tomaDeMuestras.CapasRodadura = [];
               }
               var capaRodadura = {
                   Id: 0 - $scope.tomaDeMuestras.CapasRodadura.length,
                   NumeroProbeta: $scope.tomaDeMuestras.CapasRodadura.length + 1,
                   Fecha: null,
                   Hora: null,
                   Localizacion: null,
                   Observaciones: null,
                   Imagen: null,
                   NombreImagen: null,
               };
               $scope.tomaDeMuestras.CapasRodadura.push(capaRodadura);
           }
           $scope.CopiarCapaRodaduraAnterior = function () {
               $scope.added = true;
               var capaRodaduraAnterior = $scope.tomaDeMuestras.CapasRodadura[$scope.tomaDeMuestras.CapasRodadura.length - 1];
               var capaRodadura = {
                   Id: 0 - $scope.tomaDeMuestras.CapasRodadura.length,
                   NumeroProbeta: $scope.tomaDeMuestras.CapasRodadura.length + 1,
                   Fecha: capaRodaduraAnterior.Fecha,
                   Hora: capaRodaduraAnterior.Hora,
                   Localizacion: capaRodaduraAnterior.Localizacion,
                   Observaciones: null,
                   Imagen: null,
                   NombreImagen: null,
               }
               $scope.tomaDeMuestras.CapasRodadura.push(capaRodadura);
           }
           $scope.EliminarCapaRodadura = function (capaRodaduraIndex) {
               $scope.tomaDeMuestras.CapasRodadura.splice($scope.tomaDeMuestras.CapasRodadura.indexOf(capaRodaduraIndex), 1);
           }
           $scope.NuevoTestigo = function () {
              $scope.added = true;
            
               if ($scope.tomaDeMuestras.Testigos == null) {
                   $scope.tomaDeMuestras.Testigos = [];
               }
               var testigo = {
                   Id: 0 - $scope.tomaDeMuestras.Testigos.length,
                   NumeroProbeta: $scope.tomaDeMuestras.Testigos.length + 1,
                   Fecha: null,
                   Hora: null,
                   Localizacion: null,
                   Observaciones: null,
                   Imagen: null,
                   NombreImagen: null,
               };
               $scope.tomaDeMuestras.Testigos.push(testigo);
           }
           $scope.CopiarTestigoAnterior = function () {
               $scope.added = true;
               var testigoAnterior = $scope.tomaDeMuestras.Testigos[$scope.tomaDeMuestras.Testigos.length - 1];
               var testigo = {
                   Id: 0 - $scope.tomaDeMuestras.Testigos.length,
                   NumeroProbeta: $scope.tomaDeMuestras.Testigos.length + 1,
                   Fecha: testigoAnterior.Fecha,
                   Hora: testigoAnterior.Hora,
                   Localizacion: testigoAnterior.Localizacion,
                   Observaciones: null,
                   Imagen: null,
                   NombreImagen: null,
               }
               $scope.tomaDeMuestras.Testigos.push(testigo);
           }
           $scope.EliminarTestigo = function (testigoIndex) {
               $scope.tomaDeMuestras.Testigos.splice($scope.tomaDeMuestras.Testigos.indexOf(testigoIndex), 1);
           }
           $scope.NuevaMacrotextura = function () {
               $scope.added = true;

               if ($scope.tomaDeMuestras.Macrotexturas == null) {
                   $scope.tomaDeMuestras.Macrotexturas = [];
               }
               var macrotextura = {
                   Id: 0 - $scope.tomaDeMuestras.Macrotexturas.length,
                   NumeroProbeta: $scope.tomaDeMuestras.Macrotexturas.length + 1,
                   Fecha: null,
                   Hora: null,
                   Localizacion: null,
                   Observaciones: null,
                   Imagen: null,
                   NombreImagen: null,
               };
               $scope.tomaDeMuestras.Macrotexturas.push(macrotextura);
           }
           $scope.CopiarMacrotexturaAnterior = function () {
               $scope.added = true;
               var macrotexturaAnterior = $scope.tomaDeMuestras.Macrotexturas[$scope.tomaDeMuestras.Macrotexturas.length - 1];
               var macrotextura = {
                   Id: 0 - $scope.tomaDeMuestras.Macrotexturas.length,
                   NumeroProbeta: $scope.tomaDeMuestras.Macrotexturas.length + 1,
                   Fecha: macrotexturaAnterior.Fecha,
                   Hora: macrotexturaAnterior.Hora,
                   Localizacion: macrotexturaAnterior.Localizacion,
                   Observaciones: null,
                   Imagen: null,
                   NombreImagen: null,
               }
               $scope.tomaDeMuestras.Macrotexturas.push(macrotextura);
           }
           $scope.EliminarMacrotextura = function (macrotexturaIndex) {
               $scope.tomaDeMuestras.Macrotexturas.splice($scope.tomaDeMuestras.Macrotexturas.indexOf(macrotexturaIndex), 1);
           }

           $scope.Guardar = function () {
               $scope.tomaDeMuestras.SupervisionId = $scope.idSupervision;
               dataPersistService.saveTomaDeMuestras($scope.tomaDeMuestras);
               var supervisionCompleta = dataPersistService.getSupervisionCompleta();
               if ($scope.idSupervision < 1) {
                   if ($scope.IsOnLine) {
                       tomaDeMuestrasService.post(supervisionCompleta).then(function (response) {
                           var data = response.data;
                           $scope.idSupervision = data;
                           alert("Supervisión creada correctamente.");
                           dataPersistService.saveTomaDeMuestras(null);
                           tomaDeMuestrasService.getTomaDeMuestras($scope.idSupervision)
                                             .then(function (response) {
                                                 var data = response.data;
                                                 $scope.tomaDeMuestras = data;
                                             });
                       }).catch(function (response) {
                           $scope.error = tomaDeMuestrasService.obtenerPrimerErrorModelState(response.data);
                           alert($scope.error);
                       });
                   } else {
                       supervisionCompleta.DatosSupervision.Id = $scope.idSupervision;
                       try {
                           storage.set("supervision" + $scope.idSupervision, supervisionCompleta);
                           alert("Supervisión creada correctamente.");
                       } catch (error) {
                           alert("Error al crear la supervisión.");
                       }
                   }
               } else {
                   if ($scope.IsOnLine) {
                       tomaDeMuestrasService.put(supervisionCompleta).then(function (response) {
                           var data = response.data;
                           alert(data);
                           dataPersistService.saveTomaDeMuestras(null);
                           tomaDeMuestrasService.getCodigoSupervision($scope.idSupervision)
                                   .then(function (response) {
                                       var data = response.data;
                                       dataPersistService.setTextoCabecera("<span class='navbar-tit'>" + data[0] + "</span><br><span class='navbar-subtit'>" + data[1] + "</span>");
                                       $rootScope.title = dataPersistService.getTextoCabecera();
                                   });
                       }).catch(function (response) {
                           $scope.error = tomaDeMuestrasService.obtenerPrimerErrorModelState(response.data);
                           alert($scope.error);
                       });
                   } else {
                       alert("Error de conexión.");
                       /*try {
                           storage.set("supervision" + $scope.idSupervision, supervisionCompleta);
                           alert("Supervisión guardada correctamente.");
                       } catch (error) {
                           alert("Error al guardar la supervisión.");
                       }*/
                   }
               }
           }

           if ($scope.IsOnLine) {
               //Funcion para mostrar u ocultar el boton de guardar
               $scope.supervision = dataPersistService.getDatosSupervision();
               $scope.MostrarBotonGuardar = function () {
                   if ($scope.authentication.rol == "Consultor") {
                       if ($scope.supervision.Username.toUpperCase() != $scope.authentication.userName.toUpperCase()) {
                           return false;
                       }
                   }
                   return true;
               }
           } else {
               $scope.MostrarBotonGuardar = function () {
                   return true;
               }
           }

           $scope.Anterior = function () {
               dataPersistService.saveTomaDeMuestras($scope.tomaDeMuestras);
               $location.path("/puestasobra/" + $scope.idSupervision);
           }
       }]);